
import { defineComponent, ref } from "vue";
import { approvalService } from "@/service";
import { useRoute } from "vue-router";
import { ImagePreview } from "vant";
export default defineComponent({
  name: "PassList",
  setup() {
    const approvalPassLists = ref<any>([]);
    const route = useRoute();
    const getApprovalPassLists = async () => {
      const { data } = await approvalService.getApprovalPassLists(route.params.id as string);
      approvalPassLists.value = data.data;
    };
    getApprovalPassLists();
    const showPic = (pass_pic: string) => {
      ImagePreview([pass_pic]);
    };
    return {
      approvalPassLists,
      showPic
    };
  }
});
